import Imagen from '../img/personas.jpg';
import Imagen1 from '../img/equipo.jpg';
import Imagen2 from '../img/proyectos.jpg';

const InfoServicios = [
    {
        id: "CPersonal",
        img: Imagen,
        title: "Coaching Personal",
        description: "Las sesiones individuales de coaching se centran en proporcionar interacciones personalizadas con los clientes, estableciendo objetivos claros y colaborando en el desarrollo de estrategias específicas para alcanzar dichos objetivos. Este proceso implica una evaluación periódica del proceso del cliente, ajustando el enfoque de la capacitación según sea necesario, y brindando un seguimiento continuo y apoyo adicional para garantizar el éxito en la consecución de los objetivos establecidos.  Durante nuestras sesiones, nos enfocaremos en adaptar nuestras interacciones para satisfacer las necesidades únicas de cada cliente. Establecemos metas claras y alcanzables, diseñamos estrategias efectivas para alcanzarlas y monitoreamos de cerca el progreso realizado. Si es necesario, ajustamos nuestras tácticas para garantizar resultados óptimos. Nuestro objetivo es proporcionar un ambiente de apoyo donde los clientes puedan alcanzar sus objetivos de manera efectiva y sostenible."
    }, {
        id: "CEquipos",
        img: Imagen1,
        title: "Coaching de Equipos",
        description: "El coaching de equipos ofrece una gama de servicios diseñados para optimizar la efectividad y eficiencia de los grupos de trabajo. Nuestros servicios incluyen sesiones de entrenamiento individualizado donde se establecen objetivos claros y se proporciona orientación para mejorar la colaboración y el desempeño de los equipos. Además, realizamos evaluaciones periódicas del progreso del equipo, ajustando el enfoque del coaching según sea necesario y ofreciendo un seguimiento continuo para garantizar resultados exitosos. Este servicio está diseñado para mejorar la eficiencia, la comunicación y la cohesión dentro de los equipos de trabajo, contribuyendo al logro de metas y al desarrollo de un ambiente laboral productivo, armonioso y exitoso. Nuestra metodología de coaching de equipos se adapta a las necesidades específicas de cada grupo, promoviendo un ambiente de trabajo colaborativo y estimulante que potencia el rendimiento y la satisfacción de todos los miembros del equipo."
    }, {
        id: "CProyectos",
        img: Imagen2,
        title: "Coaching de Proyectos",
        description: "El coaching de proyectos ofrece servicios altamente personalizados, desde la gestión de proyectos individuales hasta consultorías estratégicas diseñadas para garantizar el éxito en la implementación de proyectos comerciales clave. Nuestros servicios abarcan una amplia gama de actividades, incluyendo planificación detallada, ejecución, seguimiento, control y toma de decisiones efectivas durante todo el ciclo de vida del proyecto. Nos especializamos en trabajar estrechamente con gerentes y supervisores para implementar las mejores prácticas y estrategias, asegurando así el logro de los objetivos establecidos. Nuestro enfoque profesional y orientado a sus resultados tiene como objetivo maximizar la eficiencia y minimizar los riesgos asociados con la ejecución de proyectos, contribuyendo al éxito y crecimiento sostenible de su negocio. Al colaborar con nosotros, puede estar seguro de recibir asesoramiento experto y dedicado, así como soluciones adaptadas a sus necesidades específicas, para lograr resultados óptimos en cada etapa de su proyecto. Estamos comprometidos con su éxito y trabajamos incansablemente para impulsar el rendimiento y la excelencia en la implementación de sus iniciativas empresariales."
    }
];

export default InfoServicios;