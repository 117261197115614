// Conocenos.js
import React from 'react';
import { Link, Outlet } from 'react-router-dom';

function Conocenos() {
  return (
    <div>
      <h1>Conócenos</h1>
      <nav>
        <ul>
          <li>
            <Link to="quien_soy">Quién Soy</Link>
          </li>
          <li>
            <Link to="como_lo_hacemos">Cómo Lo Hacemos</Link>
          </li>
          <li>
            <Link to="mision_vision">Misión y Visión</Link>
          </li>
        </ul>
      </nav>
      <Outlet />
    </div>
  );
}

export default Conocenos;
