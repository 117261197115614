import react, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import Imagen2 from '../img/brujula.jpg'
import Imagen3 from '../img/foco.jpg'
import Imagen4 from '../img/aprobacion.jpg'
import Imagen5 from '../img/cambio_estado.jpg'
import Imagen6 from '../img/llegada.jpg'
import Imagen7 from '../img/personas.jpg'
import Imagen8 from '../img/equipo.jpg'
import Imagen9 from '../img/proyectos.jpg'
import Imagen10 from '../img/chats.png'
import { VscChevronLeft, VscChevronRight } from "react-icons/vsc";

export const slides = [
    Imagen10 ,
    Imagen2,
    Imagen3,
    Imagen4,
    Imagen5,
    Imagen6
]

export const texto_slides = [
    "Identifica de dónde proviene el origen real del problema, reto ó desafío.",
    "Se enfoca en los resultados personales, equipos y proyectos.",
    "Se enfoca en la solución y no en el problema.",
    "Fortalece los vínculos de comunicación, disuelve conflictos, crea compromiso, empodera a las personas, equipos y empresas.",
    "Cambia las emociones y estados negativos por positivos, cambia creencias, juicios y hábitos,  limitaciones por estados de poder, logro y éxito.",
    "Está comprobado que las empresas líderes y más exitosas cuentan con apoyo. Profesional de coaching y  entoring para sus ejecutivos, equipos y proyectos."
]

const Carusell = function Carusell({ slides }) {

    let [current, setCurrent] = useState(0);

    let previouSlide = () => {
        if (current === 0) setCurrent(slides.length - 1);
        else setCurrent(current - 1)

    }
    let nextSlide = () => {
        if (current === slides.length - 1) setCurrent(0);
        else setCurrent(current + 1)
    }

    useEffect(() => {
        const TimeNextslide = setTimeout(() => {
            nextSlide();
        }, 15000)
    })

    return (
        <div className='z-0'>
            <div className='z-0'>
                <div className="w-full bg-slate-900">
                    <div className='overflow-hidden relative'>
                        <div
                            className={'flex transition ease-out duration-400'}
                            style={{ transform: `translateX(-${current * 100}%)` }}
                        >
                            {slides.map((s, index) => (
                                <div key={index} className='w-full flex-none flex justify-center items-center '>
                                    <div className='flex flex-col md:flex-row gap-2 h-auto mx-4 p-4 md:p-8'>
                                        <div className='flex justify-center items-center'>
                                            <img src={s} className='h-96 max-w-full' alt={`slide-${index}`} />
                                        </div>
                                        <div className='flex justify-center mx-4 w-full md:w-80 items-center bg-slate-900'>
                                            <h3 className='text-xl md:text-3xl text-white text-center'>{texto_slides[index]}</h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='absolute top-0 h-full w-full flex justify-between items-center text-white px-2 text-3xl'>
                            <button onClick={previouSlide}>
                                <VscChevronLeft />
                            </button>
                            <button onClick={nextSlide}>
                                <VscChevronRight />
                            </button>
                        </div>
                        <div className='absolute bottom-0 py-1 flex justify-center gap-5 w-full'>
                            {slides.map((_, i) => (
                                <div
                                    onClick={() => setCurrent(i)}
                                    key={`circle-${i}`}
                                    className={`rounded-full w-5 h-5 cursor-pointer ${i === current ? 'bg-white' : 'bg-gray-500'}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <p className='text-xl px-8 py-11 text-center'>Actualmente en tu empresa, negocio, instituci&oacute;n o emprendimiento, est&aacute; enfrentando algunos de los
                    siguientes <span className='font-bold'>retos</span> y <span className='font-bold'>desaf&iacute;os</span> que no est&aacute; pudiendo superar.</p>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center px-4 md:px-10 pt-11'>
                    <div className='flex mx-9'>
                        <ul className='list-disc px-11'>
                            <li className='text-xl px-5'>Baja productividad de las personas y equipo.</li>
                            <li className='text-xl px-5'>Mal clima laboral, queja y pesimismo.</li>
                            <li className='text-xl px-5'>Falta de compromiso de las personas.</li>
                            <li className='text-xl px-5'>Parálisis ante el nuevo entorno económico y mundial.</li>
                            <li className='text-xl px-5'>Desmotivación.</li>
                        </ul>
                    </div>
                    <div className='flex mx-9'>
                        <ul className='list-disc px-11'>
                            <li className='text-xl px-5'>Bajas ventas</li>
                            <li className='text-xl px-5'>Falta de confianza.</li>
                            <li className='text-xl px-5'>Falta de visión y propósito.</li>
                            <li className='text-xl px-5'>Incapacidad para cumplir las metas, personales, equipo y de empresa.</li>
                        </ul>
                    </div>
                </div>
                <p className='text-xl px-8 py-11 text-center'>Estos son algunos de los retos y desaf&iacute;os en los que intervenimos con dos de las herramientas m&aacute;s poderosas para el cambio, como son el <span className='font-bold'>Coaching laboral, PNL</span> y el <span className='font-bold'>Mentoring</span> para cambiar <span className='font-bold'>estados, emociones y potenciar resultados</span></p>
            </div>
        </div>
    )
}

export default Carusell

export const Gallery = function Gallery() {
    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3 items-center md:px-8 pt-11">
                <div className=' border-1 border-gray-100 shadow-2xl shadow-slate-400'>
                    <img className="h-[30%] max-w-full rounded-lg p-4" src={Imagen7} alt="" />
                    <h3 className='text-xl font-bold text-center'>PERSONAS</h3>
                </div>
                <div className=' border-1 border-gray-100 shadow-2xl shadow-slate-400'>
                    <img className="h-[30%] max-w-full rounded-lg p-4" src={Imagen8} alt="" />
                    <h3 className='text-xl font-bold text-center'>EQUIPOS</h3>

                </div>
                <div className=' border-1 border-gray-100 shadow-2xl shadow-slate-400'>
                    <img className="h-[30%] max-w-full rounded-lg p-4" src={Imagen9} alt="" />
                    <h3 className='text-xl font-bold text-center'>PROYECTOS</h3>
                </div>
            </div>

            <p className='text-xl font-bold py-11 text-center'>
                Nuestro enfoque de trabajo en las personas, equipos y proyectos es:
            </p>

            <div className='flex justify-center ms-20 py-11'>
                <ul className='list-disc'>
                    <li className='text-xl'>SER: El ser que están siendo las personas, y el ser que pueden ser, valores, creencias, juicios,
                        identidad, emociones y estados.</li>
                    <li className='text-xl'>HACER: Hábitos, conductas y desempeño.</li>
                    <li className='text-xl'>TENER: Resultados reales VS resultados esperados.</li>
                </ul>
            </div>
        </div>
    )
}