import Carusell,{slides,Gallery,texto_slides} from '../components/Componen_index'

const Inicio = function Inicio() {
    return (
        <div>            
            <Carusell slides={slides} />
            <Gallery/>
        </div>
    )
}

export default Inicio