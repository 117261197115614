import { useState } from 'react';
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Transition,
} from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Imagen from '../img/logo.png'
import { Link, Outlet } from 'react-router-dom'

const navigation = [
    { name: 'Inicio', href: '/', current: true },
    {
        name: 'Conocenos',
        current: false,
        submenu: [
            { name: 'Quien soy', href: 'quien_soy' },
            { name: 'Como lo Hacemos', href: 'como_lo_hacemos' },
            { name: 'Mision y Vision', href: 'mision_vision' },
        ]
    },
    { name: 'servicios', href: 'Servicios', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Layout = function Layout() {
    const [activeButton, setActiveButton] = useState(null);


    const handleButtonClick = (index) => {
        setActiveButton(index);
    };

    return (
        <div >
            <Disclosure as="nav" className="bg-gray-800">
                {({ open }) => (
                    <>
                        <div className="container mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                            <div className="relative flex h-16 items-center px-2">
                                <div className="absolute z-10 inset-y-0 left-0 flex items-center sm:hidden">
                                    {/* Mobile menu button */}
                                    <DisclosureButton className="relative inline-flex items-center justify-center rounded-md text-gray-400 hover:bg-gray-700 hover:text-white  focus:ring-2 focus:ring-inset focus:ring-white">
                                        <span className="absolute inset-0.5" />
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </DisclosureButton>
                                </div>
                                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                    <div className="flex flex-shrink-0 items-center ">
                                        <a href='/'><img
                                            className="h-12 w-auto px-1"
                                            src={Imagen}
                                            alt="logo"
                                        /></a>
                                        <a href='/'><h1 className='text-slate-100 text-center text-ls'>NELSON MENDOZA COACH</h1></a>
                                    </div>
                                    <div className="hidden sm:ml-6 sm:block">
                                        <div className="flex absolute z-10 inset-y-0 right-0 space-x-4 space-x-reverse py-4">
                                            {navigation.map((item, index) => (
                                                <div key={item.name} className="relative">
                                                    {item.submenu ? (
                                                        <Menu as="div" className="relative inline-block text-left">
                                                            <MenuButton
                                                                className={classNames(
                                                                    activeButton === index ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                                    'rounded-md px-3  text-sm font-medium end-0 text-xl'
                                                                )}
                                                                onClick={() => handleButtonClick(index)}
                                                            >
                                                                {item.name}
                                                            </MenuButton>
                                                            <Transition
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <MenuItems className="z-10 right-0 mt-2 w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-40">
                                                                    {item.submenu.map((submenuItem) => (
                                                                        <MenuItem key={submenuItem.name}>
                                                                            {({ active }) => (
                                                                                <Link
                                                                                    to={submenuItem.href}
                                                                                    className={classNames(
                                                                                        active ? 'bg-gray-100' : '',
                                                                                        'block px-4 py-2 text-sm text-gray-700'
                                                                                    )}
                                                                                >
                                                                                    {submenuItem.name}
                                                                                </Link>
                                                                            )}
                                                                        </MenuItem>
                                                                    ))}
                                                                </MenuItems>
                                                            </Transition>
                                                        </Menu>
                                                    ) : (
                                                        <Link
                                                            to={item.href}
                                                            className={classNames(
                                                                activeButton === index ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                                'rounded-md px-3 py-2 text-sm font-medium end-0 text-xl'
                                                            )}
                                                            aria-current={item.current ? 'page' : undefined}
                                                            onClick={() => handleButtonClick(index)}
                                                        >
                                                            {item.name}
                                                        </Link>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="sm:hidden">
                            <div className="space-y-1 px-2 pb-3 pt-2">
                                {navigation.map((item, index) => (
                                    <div key={item.name}>
                                        {item.submenu ? (
                                            // Si el item tiene submenu, usa un Disclosure para desplegar las opciones
                                            <Disclosure>
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button
                                                            className={classNames(
                                                                activeButton === index ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                                'block w-full text-left rounded-md px-3 py-2 text-base font-medium'
                                                            )}
                                                            onClick={() => handleButtonClick(index)}
                                                        >
                                                            {item.name}
                                                        </Disclosure.Button>
                                                        {open && (
                                                            <Disclosure.Panel className="ml-4 space-y-1">
                                                                {item.submenu.map((submenuItem) => (
                                                                    <Link
                                                                        key={submenuItem.name}
                                                                        to={submenuItem.href}
                                                                        className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white"
                                                                    >
                                                                        {submenuItem.name}
                                                                    </Link>
                                                                ))}
                                                            </Disclosure.Panel>
                                                        )}
                                                    </>
                                                )}
                                            </Disclosure>
                                        ) : (
                                            // Si el item no tiene submenu, simplemente muestra el botón
                                            <Disclosure.Button
                                                as={Link}
                                                to={item.href}
                                                className={classNames(
                                                    activeButton === index ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'block rounded-md px-3 py-2 text-base font-medium'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                                onClick={() => handleButtonClick(index)}
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </Disclosure.Panel>


                    </>
                )}
            </Disclosure>
            <div>
                <Outlet />
            </div>
        </div>
    )
}

export default Layout
