import Imagen1 from "../img/redes_sociales/gmail.png"
import Imagen2 from "../img/redes_sociales/facebook.png"
import Imagen3 from "../img/redes_sociales/whatsapp.png"
import Imagen4 from "../img/redes_sociales/youtube.png"

export function Redes() {
    return <div>
        <div className="flex flex-col  items-end m-2">
            <a href="mailto:nelsonmendozacoach@gmail.com" target="_blank">
                <img src={Imagen1} className="w-11" />
            </a>
            <a href="https://www.youtube.com/channel/UCZUWx-5cJ9GEbg0-dsmCzxg" target="_blank">
                <img src={Imagen4} className="w-11" />
            </a>
            <a href="https://www.facebook.com/share/tbWSfmwaRceZHYh8/" target="_blank">
                <img src={Imagen2} className="w-11"/>
            </a>
            {/*<a href="" target="_blank">
                <img src={Imagen3} className="w-11"/>
            </a>*/}
        </div>
    </div>
}
