import React from 'react';
import Imagen from '../img/360.webp'

function ComoLoHacemos() {
  return (
    <div>

      <h1 className='text-2xl py-4 text-center font-bold'>¿C&Oacute;MO LO HACEMOS?</h1>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mx-4 p-8'>
        <div className='flex justify-center items-center'>
          <img src={Imagen} className='h-[50%] max-w-full' />
        </div>
        <div className='flex justify-center items-center'>
          <p className='text-3xl'>
            Indagamos en profundidad a todos los niveles internos y externos a la empresa para tener una visi&oacute;n 360
            grados del problema, reto o desaf&iacute;o que aqueja a la organizaci&oacute;n para crear las mejores soluciones.
            Con el 360 descubrimos c&oacute;mo indagamos a nivel individual, equipo y empresa.
          </p>
        </div>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mx-4 p-4'>
        <div className='flex justify-center items-center'>
          <ul className='list-disc px-11'>
            <li className='text-xl px-5'>¿Qu&eacute; los est&aacute; deteniendo?</li>
            <li className='text-xl px-5'>¿De d&oacute;nde viene realmente el problema?</li>
            <li className='text-xl px-5'>¿C&oacute;mo era la situaci&oacute;n de la empresa cuando las cosas marchaban bien?</li>
            <li className='text-xl px-5'>¿Cu&aacute;ndo se perdi&oacute; el rumbo y los resultados deseados?</li>
          </ul>
        </div>
        <div className='flex justify-center items-center'>
          <ul className='list-disc px-11'>
            <li className='text-xl px-5'>¿Qu&eacute; es lo que desean verdaderamente las personas y los equipos?</li>
            <li className='text-xl px-5'>¿Hacia d&oacute;nde se dirige la empresa?</li>
            <li className='text-xl px-5'>¿Qu&eacute; es lo que verdaderamente necesitan para alcanzar su misi&oacute;n, prop&oacute;sito y metas?</li>
          </ul>
        </div>
      </div>

      <p className='text-3xl flex justify-center items-center mx-12 py-7'>
        Nuestra compromiso es investigar a fondo y generar soluciones integrales que se originan en la
        transformaci&oacute;n de emociones y estados negativos y limitantes hacia positivos.
        Hemos constatado que, al lograr esta transformaci&oacute;n, independientemente de la historia
        pasada o de la magnitud de los problemas actuales o futuros, se produce un cambio genuino,
        sostenible y perdurable.
      </p>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-1 mx-12 py-7'>
        <div className='flex justify-center items-center'>
          <p className='text-3xl'>
            Trabajamos en sesiones de <span className='font-bold'>coaching</span> y <span className='font-bold'>mentoring</span>:
          </p>
        </div>
        <div className='flex justify-center items-center'>
          <ul className='list-disc '>
            <li className='text-3xl '>Personas en lo individual.</li>
            <li className='text-3xl '>Ejecutivos.</li>
            <li className='text-3xl '>Equipos</li>
          </ul>
        </div>
      </div>
      
      <p className='text-3xl flex justify-center items-center mx-12 py-7'>
        Nos gustaría poder dialogar contigo, si tienes algunos de los problemas, retos o desafíos que estén limitando
        a tu empresa, negocio o emprendimiento.
      </p>
    </div>
  );
}

export default ComoLoHacemos;