import './App.css';
import * as React from "react";
import {  Routes, Route } from "react-router-dom";
import Layout from './Pages/Layout'
import Inicio from './Pages/Inicio'
import Conocenos from './Pages/Conocenos'
import QuienSoy from '../src/Pages/quien_soy';
import ComoLoHacemos from '../src/Pages/como_lo_hacemos';
import MisionVision from '../src/Pages/mision_vision';
import Servicios from './Pages/Servicios'
import Default from './Pages/Default'
import { Footer } from './components/Footer';
import { Redes } from '../src/components/Redes_sociales'

function App() {
  return (
    <div>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Inicio />} />
            <Route path='/Conocenos' element={<Conocenos />} />
            <Route path='quien_soy' element={<QuienSoy />} />
            <Route path='como_lo_hacemos' element={<ComoLoHacemos />} />
            <Route path='mision_vision' element={<MisionVision />} />
            <Route path='/Servicios' element={<Servicios />} />
            <Route path='*' element={<Default />} />
          </Route>
        </Routes>
      <Redes />
      <Footer />
    </div>
  );
}

export default App;
