import Imagen from '../img/logo.png'

export function Footer() {
    const email = 'mailto:nelsonmendozacoach@gmail.com'
    return (
        <div className="bg-gray-800">
        <div className="mx-auto px-4 md:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 py-6">
            <div className='flex flex-col items-center justify-center'>
              <img className='max-h-36 px-6 w-auto' src={Imagen} alt='logo' />
            </div>
            <div className='text-center flex flex-col items-center justify-center'>
              <ul className='text-slate-100 font-medium'>
                <li>Cambiamos <span>Estados y Emociones</span></li>
                <li>Potenciamos Resultados</li>
                <li>Personas, Equipos y Empresas</li>
              </ul>
            </div>
            <div className='px-5 flex flex-col items-center justify-center'>
              <ul className='text-slate-100 font-medium'>
                <li>Contacto +57 316 339 5977</li>
                <li>Correo: <a href={email}>nelsonmendozacoach@gmail.com</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
}