const  Default = function Default() {
    return (
        <div>
            {/* Aquí debes colocar el contenido de tu página de inicio */}
            <h1>Bienvenido a la página de Default</h1>

        </div>
    )
}

export default Default