import { useState } from 'react';
import InfoServicios from '../components/InfoServicios';
const Servicios = function Servicios() {
    const [activeServices, setActiveServices] = useState([]);

    const handleServiceClick = (id) => {
      setActiveServices(prevActiveServices =>
        prevActiveServices.includes(id)
          ? prevActiveServices.filter(serviceId => serviceId !== id) 
          : [...prevActiveServices, id] 
      );
    };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mx-8 py-8 h-auto">
        {InfoServicios.map((service, index) => (
          <div
            key={index}
            className="border-1 border-gray-700 shadow-2xl shadow-slate-600 h-auto cursor-pointer"
            onClick={() => handleServiceClick(service.id)}
          >
            <div className="m-8">
              {activeServices.includes(service.id) ? (

                <div className=" h-auto py-24 md:py-0 md:h-auto">
                  <div className="h-auto text-xl">
                    <p>{service.description}</p>
                  </div>
                </div>
              ) : (

                <>
                  <h1 className="text-center text-xl font-bold">{service.title}</h1>
                  <div className="flex justify-center items-center m-4">
                    <img src={service.img} className="py-8 h-auto  flex flex-col" alt={service.title} />
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>

    </div>
  );


};

export default Servicios;