import React from 'react';

function MisionVision() {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-2 mx-10 py-4'>
      <div className='items-center justify-center  grid-cols-1'>
        <h1 className='text-3xl font-bold text-center'>Visi&oacute;n</h1>
        <p className='text-xl'>
          Facilitar el empoderamiento y crecimiento personal de mis clientes, guiándolos hacia el descubrimiento y la realización de su máximo potencial. A través de una relación de confianza, apoyo y desafío, me comprometo a acompañar a mis clientes en su viaje de autodescubrimiento, ayudándolos a establecer metas claras, superar obstáculos y desarrollar habilidades prácticas que les permitan alcanzar una vida más plena y significativa.
        </p>
      </div>
      <div className=' items-center justify-center grid'>
        <h1 className='text-3xl font-bold text-center'>Misi&oacute;n</h1>
        <p className=' text-xl'>
          Visualizo un mundo en el que todas las personas se sientan capacitadas para alcanzar su máximo potencial y vivir una vida plena y significativa. En este mundo, el coaching, la programación neurolingüística y la metafísica son ampliamente eficaces como una herramienta poderosa para el crecimiento personal y profesional. He fusionado estas poderosas artes para dar un resultado efectivo al momento de llegar al punto de la MENTORÍA tener la solución clara, aspiro a ser un agente de cambio positivo, inspirando a otros a descubrir sus fortalezas, superar sus limitaciones y convertirsen en líderes de su propio destino. Trabajo hacia un futuro en el que el coaching sea accesible para todas las personas, independientemente de su origen o circunstancias, y donde juntos podamos construir una comunidad de individuos empoderados y en constante evolución.
        </p>
      </div>
      <div className='grid-cols-1 items-center justify-center py-12'>
        <h1 className='text-center font-bold text-3xl'>Mi Objetivo</h1>
        <p className='text-xl'>
          Es inspirar y motivar a mis clientes para que se conviertan en líderes de sus propios caminos, capacitándolos para tomar decisiones conscientes y crear el cambio positivo que desean ver en sus vidas y en el mundo que los rodea.
        </p>
      </div>

      <div className='py-12'>
        <h1 className='text-center font-bold text-3xl mb-6'>Valores</h1>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mx-6'>
          <div className='flex justify-center items-center px-10'>
            <ul className='list-disc m-4'>
              <li className='text-xl'>Respeto</li>
              <li className='text-xl'>Confidencialidad</li>
              <li className='text-xl'>Sinceridad</li>
              <li className='text-xl'>Sentido de pertenencia</li>
              <li className='text-xl'>Compromiso</li>
              <li className='text-xl'>Tolerancia</li>
              <li className='text-xl'>Crecimiento</li>
            </ul>
          </div>
          <div className='flex justify-center items-center px-10'>
            <ul className='list-disc m-4'>
              <li className='text-xl'>Honestidad</li>
              <li className='text-xl'>Integridad</li>
              <li className='text-xl'>Profesionalismo</li>
              <li className='text-xl'>Confianza</li>
              <li className='text-xl'>Responsabilidad</li>
              <li className='text-xl'>Empatía</li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  );
}

export default MisionVision;