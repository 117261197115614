import React from 'react';
import Imagen from '../img/photo_coach.jpeg'

export default function QuienSoy() {
    return (
        <div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-2 py-10 m-5 '>
                <div className='flex items-center justify-center'>
                    <img src={Imagen} className=' h-full max-w-full'/>
                </div>
                <div className='flex items-center justify-center'>
                    <p className='text-xl p-2' >
                    Me llamo Nelson Fernando Mendoza, y poseo m&aacute;s de dos d&eacute;cadas de dedicaci&oacute;n y experiencia en el &aacute;mbito del coaching, especializ&aacute;ndome en la gesti&oacute;n del tiempo en &aacute;reas laborales, ontol&oacute;gicas y en la Programaci&oacute;n Neurolingü&iacute;stica (PNL), as&iacute; como en mentor&iacute;a. Mi formaci&oacute;n acad&eacute;mica ha sido obtenida en instituciones de renombre como la CASE WESTERN RESERVE UNIVERSITY, AXON TRADING y el POLIT&Eacute;CNICO SUPERIOR DE MEDELL&Iacute;N.
                            <br></br>
                            Cuando hablamos de liderazgo y desarrollo personal, aprender de los grandes mentores es esencial para alcanzar el &eacute;xito. Figuras como Jim Rohn, Brian Tracy, Joe Girard y John Maxwell han transformado vidas y organizaciones a trav&eacute;s de sus enseñanzas. Si aspiras a mejorar tus resultados y llevar tus ventas o gesti&oacute;n del tiempo a niveles superiores, estudiar a referentes como Alex Dey, Robert Kiyosaki, Napoleon Hill, Jim Rohn, y el legendario samur&aacute;i Miyamoto Musashi te ofrecer&aacute; las herramientas necesarias para triunfar.
                            <br></br>
                            Al elevar nuestros est&aacute;ndares y aprovechar el poder de la mente maestra, transformamos nuestras experiencias en algo extraordinario. Poder afirmar con certeza “¡S&iacute; se puede!” y desarrollar una confianza inquebrantable para tomar decisiones con altos est&aacute;ndares de &eacute;xito son claves para el crecimiento personal. Mentores como Tony Robbins, Daniel Goleman, Genevieve Behrend y Bob Proctor son guías esenciales para este viaje hacia la excelencia.
                            <br></br>
                            Vivir una vida plena de prosperidad, alegr&iacute;a, amor, paz y abundancia requiere estar en armon&iacute;a con las fuerzas del universo, o, como lo llamo personalmente, Dios—aunque puedes utilizar el t&eacute;rmino que mejor resuene contigo. Sentir el privilegio y el honor de estudiar biograf&iacute;as de personas de gran impacto es una experiencia incomparable.
                            <br></br>
                            Entre estos referentes destacan Billy Graham, Joel Osteen, Conny M&eacute;ndez, el pastor Charles Stanley y Mario Alonso Puig, quienes han dejado una huella imborrable en sus campos. Al fusionar sus enseñanzas, podemos crear lo que llamamos la “Gran Mente Maestra hacia el &Eacute;xito Total”.
                            <br></br>
                            ¿Est&aacute;s listo para unirte a esta lista de &eacute;xitos? Perm&iacute;tenos acompañarte en este extraordinario viaje hacia el &eacute;xito y la realizaci&oacute;n personal.
                            <br></br>
                            Como coach, mi compromiso se centra en guiar de manera efectiva a individuos y equipos hacia el logro de sus metas, liderando procesos de transformaci&oacute;n con un enfoque centrado en la calidad y la mejora continua. Mi labor se basa en un acompañamiento personalizado que fomenta el desarrollo de habilidades, la definici&oacute;n clara de objetivos y la implementaci&oacute;n de estrategias eficaces.
                            <br></br>
                            Mi dedicaci&oacute;n inquebrantable radica en ofrecer un servicio profesional que inspire confianza, propicie resultados tangibles y promueva el crecimiento tanto personal como profesional en el camino hacia el &eacute;xito.
                    </p>
                </div>
            </div>
        </div>
    )

}